<template>
  <div class="container">
    <el-dialog title="请选择" width="600px" v-model="dialogVisible">
      <!-- header -->
      <div class="header-wrap">
        <p>提示：</p>
        <p>1.若客户添加多名助手，则以最近添加助手的名义进行发送</p>
        <p>
          2.未满40人的群可以直接将客户拉进群，无需客户同意，超过40人的客户群需要用户同意才能进群
        </p>
      </div>
      <el-table
        :data="invites"
        ref="multipleTable"
        class="tablessp"
        style="width: 100%"
        border
      >
        <el-table-column prop="RobotName" label="助手名称"></el-table-column>
        <el-table-column prop="GroupName" label="请选择群聊" align="center">
          <template #default="scope">
            <el-select
              v-model="scope.row.groupItem"
              value-key="GroupID"
              placeholder="请选择"
              :disabled="!canEdit"
              v-if='scope.row.GroupList&&scope.row.GroupList.length>0'>
              <el-option
                v-for="item in scope.row.GroupList"
                :key="item.GroupID"
                :label="item.GroupName"
                :value="item"
              ></el-option>
            </el-select>

            <div v-else>
              无数据
            </div>
          </template>
        </el-table-column>
        <el-table-column label="群内人数" align="center">
          <template #default="scope">
            <p>{{ scope.row.groupItem?scope.row.groupItem.UserCount:0}}</p>
          </template>
        </el-table-column>
      </el-table>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { CircleCheckFilled } from "@element-plus/icons";
import { GetPrivateSendRobotList} from "@/helper/robot";
export default {
  components: { CircleCheckFilled },
  setup(props, context) {
    const dialogVisible = ref(false);
    const edit = ref(false);
    let canEdit = ref(true)
    const data = reactive({
      groupItem: {},
    });
    const currentIndex = ref(0);
    const multipleTable = ref(null);

    function initCop(item, index,obj={},canedit) {
      dialogVisible.value = true;
      canEdit.value = canedit?true:false
      if(!canedit){
        let arr = []
        for(let item of item.InviteRule){
          item.groupItem = {
            GroupID: item.GroupID,
            GroupName: item.GroupName,
            UserCount:item.UserCount
          }
          item.GroupList =[item.groupItem]
          arr.push(item)
        }
        invites.value = arr
      } else {
        if (item) {
          edit.value = true;
          currentIndex.value = index;
          serachInvites(item,obj);
        } else {
          edit.value = false;
          serachInvites(item,obj);
        }
      }
      
    }

    //图片
    const invites = ref([]);
    const groupId = ref("");
    //搜索
    function serachInvites(item,whereobj) {
      GetPrivateSendRobotList(whereobj).then((res) => {
        if (res.List && res.List.length > 0) {
          if (item) {
            let obj = {};
            for(let item of item.InviteRule){
              obj[item.RobotID] = {
                GroupID: item.GroupID,
                GroupName: item.GroupName,
                UserCount:item.UserCount
              };
            }
            res.List.forEach((item) => {
              item.groupItem = obj[item.RobotID];
            });
          } else {
            res.List.forEach((item) => {
              item.groupItem = {};
            });
          }
        }
        invites.value = res.List||[];
      });
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction() {
      let flag = 0
      let robotArr = []
      for(let item of invites.value){
        if(item.GroupList&&item.GroupList.length>0&&!item.groupItem.GroupID){
          flag=1
        } else if(!item.GroupList||item.GroupList.length<=0){
          robotArr.push(item.RobotName)
        }
      }

      if(flag==1){
        ElMessage({
          type:"error",
          message:"请选择群"
        })
        return
      } 
      if(robotArr.length>0){
        ElMessage({
          type:"error",
          message:`助手【${robotArr.join(',')}】未开群，请排除该助手的客户`
        })
        return
      }

      dialogVisible.value = false;
      context.emit(
        "onConfirm",
        invites.value,
        edit.value,
        currentIndex.value
      );
    }

    return {
      initCop,
      dialogVisible,
      confirmAction,
      invites,
      serachInvites,
      multipleTable,
      edit,
      data,
      currentIndex,
      canEdit
    };
  },
};
</script>
<style lang='scss' scoped>
.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 8px;
  color: #666;
}

.body-wrap {
  width: 100%;
}
</style>