<template>
  <div class="container">
    <el-dialog title="关键字" width="600px" v-model="dialogVisible">
        <div class="body-wrap">
            {{keywords}}
        </div >
      <template #footer>
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import {GetForbiddenWords} from "../helper/group";

export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const keywords = ref("");

    function initCop() {
        dialogVisible.value = true;
        GetForbiddenWords().then(res=>{
            keywords.value = res.ForbiddenWords.join(",")
        })
    }

    return {
      dialogVisible,
      initCop,
      keywords
      
    };
  },
};
</script>
<style lang='scss' scoped>
.body-wrap{
    width: 100%;
    max-height: 300px;
    overflow: scroll;
}
</style>