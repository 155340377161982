<template>
  <div class="container">
    <el-dialog title="添加链接" width='600px' v-model="dialogVisible">
        <!-- header -->
        <div v-if="!isCreate">
            <div class="header-wrap">
                <el-input placeholder="请输入链接标题/编号" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="searchLinks"></el-input>
                <div>
                    <el-button type="primary" @click="isCreate=true">新建链接</el-button>
                </div>
            </div>
            <div class="body-wrap">
                <div class="cell-wrap">
                    <div :class="{selectBorder:item.isSelect,noSelectBorder:!item.isSelect}" class="cell" v-for="(item,index) in links" :key="index" @click="selectAction(index)">
                        <div class="cell-left">
                            <p>【{{item.ID}}】{{item.MsgTitle}}</p>
                            <p class="cell-desc">{{item.MsgDesc}}</p>
                        </div>
                        <img :src="item.MsgContent">
                        <div class="cell-tip" v-if="item.isSelect">
                            <el-icon :size="25" :color="'#BFB18A'">
                                <CircleCheckFilled />
                            </el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="cop-width" v-else>
            <CreateLinkCop @submit="submitAction" @cancel="isCreate=false"/>
        </div>
          <div class="page-foot">
          <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
          />
        </div>
      <template #footer >
        <div class="dialog-footer" v-if="!isCreate">
          <el-button @click="dialogVisible = false" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted} from 'vue'
import CreateLinkCop from"./CreateLinkCop"
import { CircleCheckFilled } from '@element-plus/icons';
import {GetMaterialList} from '../../helper/material'
import TableFoot from "@/components/TableFoot"
export default {
    name:"LinkSendCop",
    components:{CreateLinkCop,CircleCheckFilled,TableFoot},
    setup(props,context) {
    const dialogVisible = ref(false);
    const isCreate = ref(false);
    const existCount = ref(0)
    const limitCount = ref(0);
    const searchObj = reactive({
        key:'',
    })

    const pageObj = reactive({
        pageIndex:1,
        pageSize:10,
        TotalRow: 0,
    })

    function initCop(limit,count){
        dialogVisible.value = true;
        limitCount.value = limit;
        existCount.value = count;
        selectLinks.value=[];
        links.value = []
        searchObj.key = ''
        searchLinks();
    }

    const links = ref([])
    const selectLinks = ref([])
    
    function searchLinks(){
        let param={
            Type:2005,
            PageNum:pageObj.pageIndex,
            PageSize:pageObj.pageSize,
            Content:searchObj.key
        }
        GetMaterialList(param).then(res=>{
            pageObj.TotalRow = res.TotalCount;
            links.value = res.List
            res.List.forEach(item=>{
                item.isSelect = false;
            })

        })
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction(){
        dialogVisible.value = false;
        context.emit('onConfirm',selectLinks.value);
    }

    //选择
    function selectAction(index){

        let item = links.value[index];
        let flag = -1
        for(let tag in selectLinks.value){
            let linkObj = selectLinks.value[tag]
            if(linkObj.ID == item.ID){
                flag = tag;
                break;
            }
        }
        
        if(flag>-1){
            item.isSelect = false;
            selectLinks.value.splice(flag,1)
        } else {
            if(selectLinks.value.length>=(limitCount.value-existCount.value)){
                return;
            }
            item.isSelect = true;
            selectLinks.value.push(item)
        }

    }

    //表单提交
    function  submitAction(obj){
        isCreate.value = false;
        // searchLinks()
        links.value.push(obj)
    }

    /****************分页********************/
     //页码改变
    function changePage(index) {
      pageObj.pageIndex = index;
      searchLinks();
    }
    function changeSize(size) {
      pageObj.pageSize = size;
      pageObj.pageIndex = 1;
      searchLinks();
    }

    return {
        changePage,
        changeSize,
        isCreate,
        initCop,
        dialogVisible,
        confirmAction,
        searchObj,
        selectLinks,
        links,
        existCount,
        limitCount,
        selectAction,
        submitAction,
        pageObj,
        searchLinks
    }
  }
}
</script>
<style lang='scss' scoped>
.header-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.cop-width{
    width: 500px;
    margin: auto;
}

.body-wrap{
    width: 100%;
    
    .cell-wrap{
        width: 100%;
        max-height: 350px;
        margin: 10px auto;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        box-sizing: border-box;

        .selectBorder {
            border: 1px solid #C1AE83;
        }
        .noSelectBorder{
            border: 1px solid #ccc;
        }
    
        .cell{
            position: relative;
            width:240px;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid #ccc;
            margin-bottom: 5px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

            img{
                width: 80px;
                height: 80px;
                margin:0 10px;
            }

            .cell-tip{
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
            }

            .cell-left{
                height: 80px;
                width: 140px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 14px;
                color: #000;
                padding-left: 5px;
                box-sizing: border-box;

                .cell-desc{
                    font-size: 12px;
                    color: #ccc
                }
                p{
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 20px;
                }

                
            }
        }
            
    }
}
.page-foot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
// :deep().el-pager li.active{
//     color: $color-common !important;
// }
</style>