<template>
  <div class="container">
    <el-dialog title="添加视频" width="600px" v-model="dialogVisible">
      <div class="header-wrap">
        <el-input placeholder="请输入视频编号/名称" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="serachVideos"></el-input>
        <div>
          <div class="circle-wrap" v-if="videoFlag == true">
            <el-progress
              type="circle"
              :percentage="videoUploadPercent"
              :stroke-width="12"
              color="#BFB18A"
            ></el-progress>
          </div>
          <div v-else>
            
            <p>上传视频不超过10M</p>
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-progress="uploadVideoProcess"
              :http-request="changeVideo"
              :auto-false="false"
            >
              <el-button type="primary">上传</el-button>
            </el-upload>
          </div>
        </div>
      </div>

      <div class="body-wrap">
        <div class="cell-wrap">
          <div
            class="cell"
            :class="{
              selectBorder: item.isSelect,
              noSelectBorder: !item.isSelect,
            }"
            v-for="(item, index) in videos"
            :key="index"
            @click="selectAction(index)"
          >
            <video controls loop>
              <source :src="item.MsgHref" />
            </video>

            <p>【{{item.ID}}】{{ item.Remark }}</p>
            <div class="cell-tip" v-if="item.isSelect">
              <el-icon :size="25" :color="'#BFB18A'">
                <CircleCheckFilled />
              </el-icon>
            </div>
          </div>
        </div>
        
      </div>
      <div class="page-foot">
           <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
          />
        </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { CircleCheckFilled } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import { setTimeout } from "timers";
import { GetMaterialList, CreateImageOrVideo } from "../../helper/material";
import TableFoot from "@/components/TableFoot"
export default {
  components: { CircleCheckFilled,TableFoot },
  setup(props, context) {
    const dialogVisible = ref(false);
    const existCount = ref(0);
    const limitCount = ref(0);
    const videoFlag = ref(false);
    const videoUploadPercent = ref(0);

    const searchObj = reactive({
      key: "",
    });
    const pageObj = reactive({
      TotalRow: 0,
      pageIndex: 1,
      pageSize: 10,
    });

    function initCop(limit, count) {
      dialogVisible.value = true;
      existCount.value = count;
      limitCount.value = limit;
      selectVideos.value = [];
      videos.value = [];
      searchObj.key = ''
      serachVideos();
    }

    //图片
    const videos = ref([]);
    const selectVideos = ref([]);

    //搜索
    function serachVideos() {
      let param = {
        Type: 2004,
        PageNum: pageObj.pageIndex,
        PageSize: pageObj.pageSize,
        Content:searchObj.key
      };
      GetMaterialList(param).then((res) => {
        pageObj.TotalRow = res.TotalCount;
        videos.value = res.List;
        res.List.forEach((item) => {
          item.isSelect = false;
        });
      });
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction() {
      dialogVisible.value = false;
      context.emit("onConfirm", selectVideos.value);
    }

    //视频
    function changeVideo(event) {
      if (videoFlag.value) {
        return;
      }
      const formData = new FormData();
      formData.append("file", event.file);
      CreateImageOrVideo({ Type: 2004, formData }).then((res) => {
        serachVideos();
        ElMessage.success({
          message: "上传成功",
          type: "success",
        });
      });
    }

    //进度条
    function uploadVideoProcess(file) {
      videoFlag.value = true;
      videoUploadPercent.value = file.percent.toFixed(0);
      //TODO:真正传输
      setTimeout(() => {
        if (videoUploadPercent.value == 100) {
          videoFlag.value = false;
        }
      }, 500);
    }

    //检测图片
    function beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (
        [
          "video/mp4",
          "video/ogg",
          "video/flv",
          "video/avi",
          "video/wmv",
          "video/rmvb",
        ].indexOf(file.type) == -1
      ) {
        ElMessage({
          type: "error",
          message: "请上传正确的视频格式",
        });
        return false;
      }
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传视频大小不能超过 10MB!",
        });
      }
      return isLt2M;
    }

    //选择
    function selectAction(index) {
      
      let item = videos.value[index];
      let flag = -1;
      selectVideos.value.forEach((videoObj, tag) => {
        if (videoObj.ID == item.ID) {
          flag = tag;
          return false;
        }
      });

      if (flag > -1) {
        item.isSelect = false;
        selectVideos.value.splice(flag, 1);
      } else {
        if (selectVideos.value.length >= limitCount.value - existCount.value) {
          return;
        }
        item.isSelect = true;
        selectVideos.value.push(item);
      }
    }

    /****************分页********************/
    //页码改变
    function changePage(index) {
      pageObj.pageIndex = index;
      serachVideos();
    }

    function changeSize(size) {
      pageObj.pageSize = size;
      pageObj.pageIndex = 1;
      serachVideos();
    }

    return {
      initCop,
      dialogVisible,
      changePage,
      changeSize,
      confirmAction,
      searchObj,
      pageObj,
      videos,
      serachVideos,
      selectAction,
      beforeAvatarUpload,
      existCount,
      limitCount,
      selectVideos,
      uploadVideoProcess,
      changeVideo,
      videoFlag,
      videoUploadPercent,
    };
  },
};
</script>
<style lang='scss' scoped>
.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

:deep() .el-progress-circle {
  width: 30px !important;
  height: 30px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

:deep() .el-progress__text {
  font-size: 8px !important;
  text-align: left;
}

.body-wrap {
  width: 100%;
  overflow: auto;
  .cell-wrap {
    width: 100%;
    max-height: 350px;
    margin: 20px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: space-between;
    .selectBorder {
      border: 1px solid #C1AE83;
    }
    
    .noSelectBorder {
      border: 1px solid #ccc;
    }

    .cell {
      position: relative;
      margin: 5px 0;
      width: 100px;
      height: 120px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .cell-tip {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      p {
        width: 100%;
        height: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 20px;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  
}
.page-foot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
// :deep().el-pager li.active {
//   color: $color-common !important;
// }
</style>