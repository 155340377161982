<template>
  <div class="container">
    <el-dialog title="添加视频号" width="600px" v-model="dialogVisible">
     <div class="body-wrap">
      <el-input placeholder="请输入视频号编号/名称" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="serachImages"></el-input>
            <div class="cell-wrap">
                <div :class="{'selectborder':item.isSelect,'noborder':!item.isSelect}" class="cell"  v-for="(item,index) in videoNums" :key="index" @click="selectAction(index)">
                    <div :class="{'bgColor':item.disabled}"></div>
                    <div class="video-header">
                        <div class="imgs">
                            <img class="videoimg" src="../../assets/imgs/video.png">
                        </div>
                        <div class="cell-desc">{{item.MsgDesc}}</div>
                    </div>
                    <img :src="item.MsgHref">
                    <p class="cell-desc">【{{item.ID}}】{{item.Remark}}</p>

                    <div class="cell-tip" v-if="item.isSelect">
                        <el-icon :size="25" :color="'#BFB18A'">
                            <CircleCheckFilled />
                        </el-icon>
                    </div>
                </div>
            </div>
        </div>
      <template #footer>
        <div class="dialog-footer">
          <div class="title">由于助手接收视频号素材会有延迟,此处只可选择添加超过30分钟的素材</div>
          <div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmAction">确 定</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { CircleCheckFilled } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import { GetMaterialList } from "@/helper/material";

export default {
  components: {
    CircleCheckFilled,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const existCount = ref(0);
    const limitCount = ref(0);
    const searchObj = reactive({
      key: "",
    });
    const pageObj = reactive({
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
    });
  
    function initCop(limit, count) {
      dialogVisible.value = true;
      limitCount.value = limit;
      existCount.value = count;
      selectvideoNums.value = [];
      videoNums.value = [];
      searchObj.key = ''
      serachImages();
          
    }

    //视频号
    const videoNums = ref([]);
    const selectvideoNums = ref([]);

    //搜索
    function serachImages() {
      let param = {
        Type: 2017,
        PageNum: pageObj.pageIndex,
        PageSize: pageObj.pageSize,
        Content:searchObj.key
      };
      GetMaterialList(param).then((res) => {
        let nowDate=new Date().getTime()
        console.log(nowDate);
        pageObj.totalCount = res.TotalCount;
        videoNums.value = res.List;
        res.List.forEach((item) => {
          item.isSelect = false;
          item.createTime=new Date(item.CreateTime).getTime()
          if(Math.abs(nowDate-item.createTime)<=(30 * 60 * 1000)){
              item.disabled=true
          }
        });
      });
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction() {
      dialogVisible.value = false;
      context.emit("onConfirm", selectvideoNums.value);
    }

    //选择
    function selectAction(index) {
      let item = videoNums.value[index];
      if(item.disabled){
        return
      }
      let flag = -1;
      for (let tag in selectvideoNums.value) {
        let videoNumObj = selectvideoNums.value[tag];
        if (videoNumObj.ID == item.ID) {
          flag = tag;
          break;
        }
      }

      if (flag > -1) {
        item.isSelect = false;
        selectvideoNums.value.splice(flag, 1);
      } else {
        if (selectvideoNums.value.length >= limitCount.value - existCount.value) {
          return;
        }
        item.isSelect = true;
        selectvideoNums.value.push(item);
      }
    }

    /****************分页********************/
    //切换分页
    function currentchange(val) {
      pageObj.pageIndex = val;
      serachImages();
    }

    return {
      initCop,
      dialogVisible,
      confirmAction,
      searchObj,
      pageObj,
      videoNums,
      serachImages,
      selectAction,
      currentchange,
      existCount,
      selectvideoNums,
      limitCount
    };
  },
};
</script>
<style lang='scss' scoped>
.header-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.body-wrap {
  width: 100%;
  overflow: auto;

  .cell-wrap {
        width: 100%;
        max-height: 500px;
        margin: 10px auto;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
    .selectborder {
      border: 1px solid #C1AE83;
    }
     .noborder{
            border:1px solid #ccc;
        }

    .cell {
        position: relative;
        margin: 5px 0px;
        width:160px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
        box-sizing: border-box;
       img{
                width: 120px;
                height: 100px;
                padding:10px;
              
            }

      .cell-tip {
            position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
      }
      .cell-desc{
            width: 100%;
            font-size: 12px;
            text-align: center;
            padding: 0 5px;
            box-sizing: border-box;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
        }
      p {
        width: 100%;
        height: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 20px;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
.video-header{
  width: 100%;
    display: flex;
    flex-shrink: 0;
      vertical-align: top;
    .imgs{
        height: 20px;
        display: flex;
        flex-shrink: 0;
        .videoimg{
            width: 100%!important;
            height: 100%!important;
            padding: 5px!important;
            display: block!important;
        }
    }
}
.bgColor{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999999;
}
.dialog-footer{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  .title{
    margin-right: 10px;
    color: red;
  }
}
:deep().el-pager li.active{
    color: $color-common !important;
}
</style>