<template>
  <div class="container">
    <el-dialog title="添加文本" width="600px" v-model="dialogVisible">
      <el-input
        id="editor"
        type="textarea"
        placeholder="请输入想要发送的内容"
        v-model="inputValue"
        contenteditable="true"
        :maxlength="textCount"
        show-word-limit
        size="medium"
        :rows="10"
        :disabled="!canEdit"
      ></el-input>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { GetForbiddenWords } from "../../helper/group";
import { ElMessageBox, ElMessage } from "element-plus";
import { emotions } from "@/assets/js/emoji";
export default {
  setup(props, context) {
    const dialogVisible = ref(false);
    const inputValue = ref("");
    const edit = ref(false);
    const canEdit = ref(true); //编辑
    const currentIndex = ref(0);
    const arr = ref([]);
    const textCount=ref(0)

    function initCop(item, index, canedit,count) {
      dialogVisible.value = true;
      canEdit.value = canedit ? true : false;
      textCount.value=count? 1900:500
      GetForbiddenWords().then((res) => {
        arr.value = res.ForbiddenWords;
      });
      if (item) {
        edit.value = true;
        inputValue.value = item.MsgContent;
        currentIndex.value = index;
      } else {
        inputValue.value = "";
        edit.value = false;
      }
    }

 
    /***************点击事件******************/
    //确定点击
    function confirmAction() { 
         let re = '';
         for(var i=0;i<arr.value.length;i++){
           console.log(arr.value.length-1);
             if(i==arr.value.length-1){
                re+=arr.value[i];
             }else{
                re+=arr.value[i]+"|";
             }
         }
         let pattern = new RegExp(re,"g");
         if(pattern.test(inputValue.value)){
            let objArr=inputValue.value.match(pattern)
            ElMessage({
              type: "warning",
              message: `内容有【${objArr}】敏感词,请重新编辑`,
            });
            return
         }

        if (!inputValue.value || inputValue.value.length <= 0) {
          ElMessage({
            type: "warning",
            message: "内容不能为空",
          });
          return;
        }
      dialogVisible.value = false;
      context.emit(
        "onConfirm",
        inputValue.value,
        edit.value || false,
        currentIndex.value
      );
    }

    return {
      inputValue,
      initCop,
      dialogVisible,
      confirmAction,
      currentIndex,
      edit,
      arr,
      canEdit,
      textCount
    };
  },
};
</script>
<style lang='scss' scoped>
:deep().el-dialog__body textarea {
  color: #000;
  border: none;
}
:deep().el-textarea__inner {
  background-color: #f9f9f9 !important;
}
:deep().el-input__count {
  background-color: #f9f9f9;
  color: #000;
}
:deep().el-textarea__inner::placeholder {
  color: #999;
}
/* 谷歌 */
:deep().el-textarea__inner::-webkit-input-placeholder {
  color: #999;
}
/* 火狐 */
:deep().el-textarea__inner:-moz-placeholder {
  color: #999;
}
/*ie*/
:deep().el-textarea__inner:-ms-input-placeholder {
  color: #999;
}
</style>