<template>
  <div>
    <div class="content-wrap">
        <div class="banner">
            <div v-for="(item,index) in btns" :key="index" class="btn-wrap" @click="chooseAction(index)">
                <img :src="item.img">
                <p>{{item.title}}</p>
            </div>
        </div>
    </div>

      <!-- 信息框 -->
    <div class="border-wrap" v-if="list.length<=0"></div>

    <!-- 信息框 -->
    <div class="border-wrap" v-if="list.length>0">
    <div class="cell-wrap" :class="{bottomcolor:(index!=list.length-1)}" v-for="(item,index) in list" :key="index" >
        <div class="cell-title" v-if="item.Type==2001">文字</div>
        <div class="cell-title" v-if="item.Type==2002">图片</div>
        <div class="cell-title" v-if="item.Type==2005">链接</div>
        <div class="cell-title" v-if="item.Type==2004">视频</div>
        <div class="cell-title" v-if="item.Type==2013">小程序</div>
        <div class="cell-title" v-if="item.Type==2017">视频号</div>
        <div class="cell-title" v-if="item.Type==123000">入群邀请</div>
        <!-- 文本 -->
        <div class="cell-content" v-if="item.Type==2001">
            <div  class="text-cell">{{item.MsgContent}}</div>
        </div>
        <!-- 图片 -->
        <div class="cell-content"  v-if="item.Type==2002">
            <div class="image-cell">
                <img :src="item.MsgContent">
                <p>{{item.Remark}}</p>
            </div>
        </div>
        
        <!-- 链接 -->
        <div class="cell-content" v-if="item.Type==2005">
            <div class="link-cell">
                <div class="cell-left">
                    <p>{{item.MsgTitle}}</p>
                    <p class="cell-desc">{{item.MsgDesc}}</p>
                </div>
                <img :src="item.MsgContent">
            </div>
        </div>
         <!-- 小程序 -->
        <div class="cell-content" v-if="item.Type==2013">
            <div class="mini-cell">
                <div class="cell-top">
                    <div></div>
                    <p>{{item.MsgTitle}}</p>
                </div>
                <p class="cell-desc">{{item.MsgDesc}}</p>
                <img :src="item.MsgHref">
                <div class="cell-foot">
                    <img src="../../assets/imgs/mini-logo.png">
                    <p>小程序</p>
                </div>
            </div>
        </div>
        <!-- 视频 -->
        <div class="cell-content" v-if="item.Type==2004">
            <div class="image-cell">
                <video controls loop> 
                    <source :src="item.MsgHref">
                </video>
                <p>{{item.Remark}}</p>
            </div>
        </div>

        <!-- 视频号 -->
        <div class="cell-content" v-if="item.Type==2017">
           <div class="videonum-cell">
                <div class="cell-header">
                    <img class="imgs" src="../../assets/imgs/video.png">
                    <div class="cell-desc">{{item.MsgDesc}}</div>
                </div>
                <img :src="item.MsgHref">
                <p>{{item.Remark}}</p>
            </div>
        </div>

        <!-- 入群邀请 -->
        <div class="cell-content" v-if="item.Type==123000">
            <div class="invite-cell">
                <div class="link-cell">
                    <div class="cell-left">
                        <div>邀请你加入群聊</div>
                        <div class="cell-desc">xxxx“邀请你加入群聊XXXXXXXXXX</div>
                    </div>
                    <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp.ssl.qhimg.com%2Ft01aab8f327e844a591.jpg&refer=http%3A%2F%2Fp.ssl.qhimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631167663&t=d1c7bab13acf0f2f07a2af66233a1b42">
                </div>
                <div class="invite-right">
                    <p>提示:</p>
                    <p>未满40人的群可以直接将客户拉进群，无需客户同意</p>
                    <p>超过40人的客户群需要用户同意才能进群</p>
                </div>
                
            </div>
        </div>
        
        <div class="cell-btn">
            <div v-if="item.Type==2001||item.Type==123000" @click="editTextAction(index,item)">
                <el-icon :size="18" :color="'#666'">
                    <Edit />
                </el-icon>
            </div>
            <div @click="deleteAction(index)">
                <el-icon :size="18" :color="'#666'">
                    <Delete />
                </el-icon>
            </div>
            
        </div>
    </div>

    </div>
    
    <div class="foot-wrap">
        <p>最多关联{{limitCount}}条内容，内容推送顺序可能会受带宽等因素影响产生变化</p>
        <p>{{list.length||0}}/{{limitCount}}条内容</p>
    </div>

    <TextSendCop ref="textSendDialog" @onConfirm="addTextAction"/>
    <ImgSendCop ref="imgSendDialog" @onConfirm="addImgAction"/>
    <LinkSendCop ref="linkSendDialog" @onConfirm="addLinkAction"/>
    <MiniSendCop ref="miniSendDialog" @onConfirm="addMiniAction"/>
    <VideoSendCop ref="videoSendDialog" @onConfirm="addVideoAction"/>
    <InviteSendCop ref="inviteSendDialog" @onConfirm="addInviteAction"/>
    <VideoNumSendCop ref="videoNumDialog" @onConfirm="addVideoNumAction"/>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from "vue";
import TextSendCop from"./TextSendCop"
import ImgSendCop from"./ImgSendCop"
import LinkSendCop from"./LinkSendCop"
import MiniSendCop from"./MiniSendCop"
import VideoSendCop from"./VideoSendCop"
import InviteSendCop from"./InviteSendCop"
import VideoNumSendCop from "./VideoNumSendCop"
import {Delete,Edit} from "@element-plus/icons"
import { ElMessageBox,ElMessage } from 'element-plus';
import { GetForbiddenWords } from "../../helper/group";

export default {
  name: "GroupMsgCop",
  components:{
      TextSendCop,ImgSendCop,LinkSendCop,MiniSendCop,VideoSendCop,InviteSendCop,Delete,Edit,VideoNumSendCop
  },
  props:{
      limit:{
          type:Number,
          default:4
      },
      typeArr:{
          type:Array,
          default:[]
      },
      removeArr:{
          type:Array,
          default:[]
      },
      materialArr:{
          type:Array,
          default:[]
      },
      isEdit:{
        type:Boolean,
        default:true
      },
      countNum:{
          type:Number,
          default:0
      }
        
  },
  setup(props, context) {
    const textBtn = { img:require("../../assets/imgs/tool_text.png"), title:"文字", type:0}
    const imgBtn = { img:require("../../assets/imgs/tool_pic.png"), title:"图片", type:1}
    const linkBtn = {img:require("../../assets/imgs/tool_link.png"),title:"链接",type:2}
    const miniBtn = { img:require("../../assets/imgs/tool_mini.png"), title:"小程序", type:3}
    const videoBtn = { img:require("../../assets/imgs/tool_video.png"), title:"视频", type:4}
    const inviteBtn = { img:require("../../assets/imgs/tool_gift.png"), title:"入群邀请", type:5}
    const videoNumBtn = { img:require("../../assets/imgs/video.png"), title:"视频号", type:6}
    const list = ref([])
    const limitCount = props.limit;
    const textlength=props.countNum

    //展示按钮集合
    const btns = ref([])
    onMounted(() => {
        list.value =[]
        if(props.typeArr.length>0){
            if(props.typeArr.indexOf('text')>-1){
                btns.value.push(textBtn)
            }
            if(props.typeArr.indexOf('img')>-1){
                btns.value.push(imgBtn)
            }
            if(props.typeArr.indexOf('link')>-1){
                btns.value.push(linkBtn)
            }
            if(props.typeArr.indexOf('mini')>-1){
                btns.value.push(miniBtn)
            }
            if(props.typeArr.indexOf('video')>-1){
                btns.value.push(videoBtn)
            }
            if(props.typeArr.indexOf('invite')>-1){
                btns.value.push(inviteBtn)
            }
            if(props.typeArr.indexOf('videoNum')>-1){
                btns.value.push(videoNumBtn)
            }
        } else {
            if(props.removeArr.length>0){
                if(props.removeArr.indexOf('text')<0){
                    btns.value.push(textBtn)
                }
                if(props.removeArr.indexOf('img')<0){
                    btns.value.push(imgBtn)
                }
                if(props.removeArr.indexOf('link')<0){
                    btns.value.push(linkBtn)
                }
                if(props.removeArr.indexOf('mini')<0){
                    btns.value.push(miniBtn)
                }
                if(props.removeArr.indexOf('video')<0){
                    btns.value.push(videoBtn)
                }
                if(props.removeArr.indexOf('invite')<0){
                    btns.value.push(inviteBtn)
                }
                if(props.removeArr.indexOf('videoNum')<0){
                    btns.value.push(videoNumBtn)
                }
            } else {
                btns.value.push(textBtn,imgBtn,linkBtn,miniBtn,videoBtn,videoNumBtn,inviteBtn)
            }
            
        }
        
    });

    function initData(arr,obj){
        console.log(arr);
        list.value = arr||[]
        whereObj = obj||{}
    } 
    
    function initText(str){
        let arr=[]
        let obj={
            Type:2001,
            MsgContent:str
        }
        arr.push(obj)
        list.value=arr ||[]
    }
    let whereObj =''
    //重置助手问题
    function resetRobot(obj){
        whereObj =obj
        let flag = -1
        for(var index in list.value){
            if(list.value[index].Type==123000){
                flag=index
                break
            }
        }
        if(flag!=-1){
            list.value.splice(flag,1)
        }
    }

    const textSendDialog = ref(null);
    const imgSendDialog = ref(null);
    const linkSendDialog = ref(null);
    const miniSendDialog = ref(null);
    const videoSendDialog = ref(null);
    const inviteSendDialog = ref(null);
    const videoNumDialog=ref(null)

    /***************点击事件*****************/
    //选择发送类型
    function chooseAction(index){
        if(!props.isEdit){
            return
        }
        let type = btns.value[index].type;
        if(list.value.length>=limitCount){
            ElMessage({
              type: 'warning',
              message: '群发内容数量已达到上限哦',
            });
            return
        }
        
        if(type == 0){
            textSendDialog.value.initCop(null,null,props.isEdit,textlength);
        } else if(type == 1){
            imgSendDialog.value.initCop(limitCount,list.value.length);
        } else if(type == 2){
            linkSendDialog.value.initCop(limitCount,list.value.length);
        } else if(type == 3){
            miniSendDialog.value.initCop(limitCount,list.value.length);
        } else if(type == 4){
            videoSendDialog.value.initCop(limitCount,list.value.length);
        } else if(type == 5){
            for(let item of list.value){
                if(item.Type == 123000){
                    ElMessage({
                        type: 'warning',
                        message: '入群邀请类型只能建1条',
                    });
                    return
                }
            }
            if(whereObj==''){
                ElMessage({
                    type: 'warning',
                    message: '请添加发送条件',
                });
                return
            }
            inviteSendDialog.value.initCop(null,0,whereObj,props.isEdit);
        }else if(type==6){
            videoNumDialog.value.initCop(limitCount,list.value.length);
        }
    }

    //文本
    function addTextAction(text,isEdit,index){
        if(isEdit){
            list.value[index].MsgContent = text;
        } else {
            list.value.push({
                Type:2001,
                MsgContent:text,
            })
        }
        context.emit("onChange",list.value)
    }

    //编辑
    function editTextAction(index,item){
        if(item.Type == 2001){
            textSendDialog.value.initCop(item,index,props.isEdit, textlength);
        }else if(item.Type == 123000){
            inviteSendDialog.value.initCop(item,index,whereObj,props.isEdit);
        }
    }

    //图片
    function addImgAction(arr){
        list.value=list.value.concat(arr)
        context.emit("onChange",list.value)
    }

    //链接
    function addLinkAction(arr){
        list.value=list.value.concat(arr)
        context.emit("onChange",list.value)
    }

    //小程序
    function addMiniAction(arr){
        list.value=list.value.concat(arr)
        context.emit("onChange",list.value)
    }

    //视频
    function addVideoAction(arr){
        list.value=list.value.concat(arr)
        context.emit("onChange",list.value)
    }

    //视频号
    function addVideoNumAction(arr) {
        list.value=list.value.concat(arr)
        context.emit("onChange",list.value)
    }


    //邀请入群
    function addInviteAction(arr,isEdit,index,groupId){
        if(isEdit){
            list.value[index].source = arr;
        }else {
            if(!props.isEdit){
                return
            }
            list.value.push({
                Type:123000,
                title:"入群邀请",
                gid:groupId,
                source:arr,
                content:{
                    title:"邀请你加入群聊",
                    desc:"xxxx“邀请你加入群聊XXXXXXXXXX",
                    url:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp.ssl.qhimg.com%2Ft01aab8f327e844a591.jpg&refer=http%3A%2F%2Fp.ssl.qhimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631167663&t=d1c7bab13acf0f2f07a2af66233a1b42"
                },
            })
        }
        context.emit("onChange",list.value)
        
    }
    //删除
    function deleteAction(index){
        if(!props.isEdit){
            return
        }
        list.value.splice(index,1)
        context.emit("onChange",list.value)
    }

    return {
        btns,
        chooseAction,
        list,
        textSendDialog,
        imgSendDialog,
        linkSendDialog,
        miniSendDialog,
        videoSendDialog,
        inviteSendDialog,
        addTextAction,
        addImgAction,
        addLinkAction,
        addVideoAction,
        addMiniAction,
        addInviteAction,
        editTextAction,
        deleteAction,
        limitCount,
        initData,
        resetRobot,
        whereObj,
        videoNumDialog,
        addVideoNumAction,
        initText,
        textlength
    };
  },
};
</script>
<style lang='scss' scoped>
.content-wrap{
    width: 600px;
    border:1px solid #ccc;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .banner{
        border-bottom: 1px solid #ccc;
        height: 30px;
        width: 100%;

        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;

        .btn-wrap:hover{
            background-color: $color-common-hover;
        }

        .btn-wrap{
            width: 80px;
            height: 100%;
            cursor: pointer;
            
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            img{
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }
            p{
                font-size: 12px;
                color: $color-common;
            }
        }
    }

}

.border-wrap{
    width: 100%;
    min-height: 100px;
    border:1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    padding: 5px 0;
}
.bottomcolor{
    border-bottom: 1px solid #ccc;
}
.cell-wrap{
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    padding: 5px 0;

    .cell-title{
        height: 100px;
        width: 70px;
        font-size: 14px;
        color: $color-common;
        flex-shrink: 1;
        text-align: center;
        padding-top: 10px;
        box-sizing: border-box;
    }

    .cell-content{
        width: calc(600px - 140px);
        height: 100%;
        display: flex;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;

        .text-cell{
            width: 100%;
            color: #000;
            word-break: break-all;
            word-wrap: break-word;
            white-space: pre-wrap;
        }

        .invite-cell{
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 10px;
            color: #666;

            .invite-left{
                margin: 0 5px;
                width:240px;
                height: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
            .invite-right{
                height: 100%;
                display: flex;
                flex-direction: column;
                p{
                    line-height: 18px;
                    font-size: 6px;
                }
            }
        }

        .image-cell{
            position: relative;
            margin: 0 5px;
            width: 100px;
            height: 120px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
            border: 1px solid #ccc;
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            img{
                width: 100%;
                height: 100%;;
            }
            p{
                width: 100%;
                height: 20px;
                background-color: rgba($color: #000000, $alpha: 0.2);
                font-size: 12px;
                position: absolute;
                bottom: 0;
                left: 0;
                line-height: 20px;
                text-align: center;
                color: #fff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
            }
        }
        .link-cell{
            position: relative;
            margin: 0 5px;
            width:240px;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #ccc;
            overflow: hidden;
            flex-shrink: 0;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
            img{
                width: 80px;
                height: 80px;
                margin:0 10px;
            }

            .cell-left{
                height: 100%;
                width: 160px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 14px;
                color: #000;
                padding: 5px 10px;
                box-sizing: border-box;

                .cell-desc{
                    font-size: 12px;
                    color: #ccc
                }
                p{
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 20px;
                }
                
            }
        }
        .mini-cell{
            position: relative;
            border: 1px solid #ccc;
            margin: 0 5px;
            width:180px;
            height: 220px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

            img{
                width: 120px;
                height: 100px;
                padding:10px;
                border-bottom: 1px solid #ccc;
            }

            .cell-tip{
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .cell-desc{
                width: 100%;
                font-size: 12px;
                color: #ccc;
                text-align: center;
                padding: 0 5px;
                box-sizing: border-box;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;

            }
            .cell-foot{
                width: 100%;
                font-size: 8px;
                display: flex;
                flex-direction: row;
                img{
                    width: 10px;
                    height: 10px;
                    border: none;
                }
            }

            .cell-top{
                width: 100%;
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                color: #000;
                box-sizing: border-box;
                

                div{
                    width: 16px;
                    height: 16px;
                    border: 1px solid #000;
                    border-radius: 50%;
                    margin: 0 5px 0 5px;
                    flex-shrink: 0;
                }
                p{
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                }
                
            }
        }

        .videonum-cell{
            position: relative;
            border: 1px solid #ccc;
            margin: 0 5px;
            width:180px;
            height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
            .cell-header{
                display: flex;
                .imgs{
                    width: 30px;
                    height: 30px;
                    padding: 5px 0px 5px 5px;
                }
            }
            .cell-desc{
                 width: 100%;
                font-size: 12px;
                text-align: center;
                padding: 0 5px;
                box-sizing: border-box;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            img{
                width: 160px;
                height: 100px;
                padding:10px;
            }
            p{
                width: 100%;
                height: 20px;
                background-color: rgba($color: #000000, $alpha: 0.2);
                font-size: 12px;
                position: absolute;
                bottom: 0;
                left: 0;
                line-height: 20px;
                text-align: center;
                color: #fff;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
            }
        }
    }

    .cell-btn{
        height: 80px;
        width: 60px;
        flex-shrink: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div{
            width: 100%;
            height: 80px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

    }

}


.foot-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #999;

}
</style>