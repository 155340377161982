<template>
  <div class="container">
    <el-dialog title="添加小程序" width='600px' v-model="dialogVisible">
        <!-- header -->
        <div class="header-wrap">
            <el-input placeholder="请输入小程序名称/编号" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="searchMinis"></el-input>
        </div>

        <div class="body-wrap">
            <div class="cell-wrap">
                <div :class="{'selectborder':item.isSelect,'noborder':!item.isSelect}" class="cell"  v-for="(item,index) in minis" :key="index" @click="selectAction(index)">
                    <div class="cell-top">
                        <img class="mini_btn" src="../../assets/imgs/chat_btn_mini.png" >
                        <p :title="item.MsgTitle">{{item.MsgTitle}}</p>
                        
                    </div>
                    <p class="msg_content">{{item.MsgContent}}</p>
                    <p class="cell-desc">{{item.MsgDesc}}</p>
                    <img class="content" :src="item.MsgHref">
                    <p class="cell-mark">【{{item.ID}}】{{item.Remark}}</p>
                    <!-- <div class="cell-foot">
                        <img src="../../assets/imgs/mini-logo.png" alt="">
                        <p>小程序</p>
                    </div> -->
                    <div class="cell-tip" v-if="item.isSelect">
                        <el-icon :size="25" :color="'#BFB18A'">
                            <CircleCheckFilled />
                        </el-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-foot">
          <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
          />
        </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted} from 'vue'
import { CircleCheckFilled } from '@element-plus/icons';
import {GetMaterialList} from '@/helper/material'
import TableFoot from "@/components/TableFoot"
export default {
    components:{CircleCheckFilled,TableFoot},
  setup(props,context) {
    const dialogVisible = ref(false);
    const existCount = ref(0)
    const limitCount = ref(0);
    const searchObj = reactive({
        key:'',
    })
    const pageObj = reactive({
        pageIndex:1,
        pageSize:10,
        TotalRow: 0,
    })

    function initCop(limit,count){
        dialogVisible.value = true;
        limitCount.value = limit;
        existCount.value = count;
        selectMinis.value=[];
        minis.value = []
        searchObj.key = ''
        searchMinis();
    }

    const minis = ref([])
    const selectMinis = ref([])
    function searchMinis(){
        let param={
            Type:2013,
            PageNum:pageObj.pageIndex,
            PageSize:pageObj.pageSize,
            Content:searchObj.key
        }
        GetMaterialList(param).then(res=>{
            pageObj.TotalRow = res.TotalCount;
            minis.value = res.List
            res.List.forEach(item=>{
                item.isSelect = false;
            })

        })
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction(){
        dialogVisible.value = false;
        context.emit('onConfirm',selectMinis.value);
    }

    //选择
    function selectAction(index){
        
        let item = minis.value[index];
        let flag = -1
        selectMinis.value.forEach((miniObj,tag)=>{
            if(miniObj.ID == item.ID){
                flag = tag;
                return false;
            }
        })
        
        if(flag>-1){
            item.isSelect = false;
            selectMinis.value.splice(flag,1)
        } else {
            if(selectMinis.value.length>=(limitCount.value-existCount.value)){
                return;
            }
            item.isSelect = true;
            selectMinis.value.push(item)
        }

    }

     /****************分页********************/
     //页码改变
    function changePage(index) {
      pageObj.pageIndex = index;
      searchMinis()
    }
    function changeSize(size) {
      pageObj.pageSize = size;
      pageObj.pageIndex = 1;
      searchMinis()
    }

    return {
        changePage,
        changeSize,
        initCop,
        dialogVisible,
        confirmAction,
        searchObj,
        selectMinis,
        minis,
        existCount,
        limitCount,
        selectAction,
        searchMinis,
        pageObj
    }
  }
}
</script>
<style lang='scss' scoped>
.header-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.body-wrap{
    width: 100%;
    
    .cell-wrap{
        width: 100%;
        max-height: 500px;
        margin: 10px auto;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        .selectborder {
            border: 1px solid #C1AE83;
        }
        .noborder{
            border:1px solid #ccc;
        }
        .cell{
            position: relative;
            // border: 1px solid #ccc;
            margin: 5px 0px;
            width:160px;
            height: 240px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
            box-sizing: border-box;

        
            .content{
                width: 120px;
                height: 100px;
                padding:10px;
                border-bottom: 1px solid #ccc;
            }

            .cell-tip{
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
            }
            .msg_content{
                width: 100%;
                font-size: 12px;
                padding:0 6px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                box-sizing: border-box;
            }
            .cell-desc{
                width: 100%;
                font-size: 12px;
                color: #ccc;
                // text-align: center;
                padding: 0 5px;
                box-sizing: border-box;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;

            }
            .cell-mark{
                 width: 100%;
                font-size: 12px;
                color: $color-common;
                text-align: center;
                padding: 0 5px;
                box-sizing: border-box;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
            }
            .cell-foot{
                width: 100%;
                font-size: 8px;
                display: flex;
                flex-direction: row;
                img{
                    width: 10px;
                    height: 10px;
                    border: none;
                }
            }

            .cell-top{
                width: 100%;
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                color: #000;
                box-sizing: border-box;
                
                .mini_btn{
                    width: 20px;
                    height: 20px;
                    padding: 5px;
                }
                div{
                    width: 16px;
                    height: 16px;
                    border: 1px solid #000;
                    border-radius: 50%;
                    margin: 0 5px 0 5px;
                    flex-shrink: 0;
                }
                p{
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color: $color-common;
                }
                
            }
        }
            
    }
    .page-foot {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }
}

:deep().el-pagination{
    width: 100%;
    box-sizing: border-box;
}
</style>