<template>
  <div class="container-wrap">
    <div class="top-wrap">
      <div>根据微信规则,为避免引起封号，即使群发请遵守以下规则:</div>
      <div>1.发送后，延迟1-3分钟在群内显示；</div>
      <div>
        2.内容不得包含微信禁止关键字(<span
          style="cursor: pointer"
          @click="showKeywordsAction"
          >显示</span
        >)
      </div>
    </div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleFormRef"
      label-width="100px"
      class="ruleForm"
    >
      <el-form-item label="群发名称" prop="name">
        <div>
          <el-input
            v-model="ruleForm.name"
            placeholder="请填写名称"
            maxlength="24"
            show-word-limit
            :disabled="!isEdit"
          ></el-input>
          <p>名字只作为辨别多个条目之用，不会显示在群发内容中</p>
        </div>
      </el-form-item>
      <el-form-item label="发送时间" prop="time">
        <div>
          <el-date-picker
            v-model="ruleForm.time"
            type="datetime"
            placeholder="请选择时间"
            style="width: 100%"
            :disabledDate="isDisadbleAction"
            @change="changeTimeAction"
            :disabled="!isEdit"
          ></el-date-picker>
          <p>发送时间必须大于当前时间</p>
        </div>
      </el-form-item>
      <el-form-item label="发送类型" prop="type">
        <div>
          <el-radio-group v-model="ruleForm.Type" :disabled="!isEdit">
            <el-radio :label="1">API发送</el-radio>
            <el-radio :label="2">RPA发送</el-radio>
          </el-radio-group>
        </div>
      </el-form-item>
      <el-form-item label="群发群聊" prop="group">
        <div class="item-wrap">
          <el-button type="primary" @click="selectGroup">选择群聊</el-button>
          <div class="item-group-count">
            已选择(<span>{{ ruleForm.group.length || 0 }}</span
            >)个群聊
          </div>
        </div>
      </el-form-item>
      <el-form-item label="群发内容" prop="content">
        <GroupMsgCop
          :removeArr="['invite']"
          :materialArr="ruleForm.content"
          @onChange="changeMsgContent"
          :isEdit='isEdit'
          ref="groupMsgRef"
        />
      </el-form-item>
      <el-form-item label="是否@所有人" v-if="ruleForm.Type==1">
        <el-switch
          v-model="ruleForm.askAll"
          active-color="#13ce66"
          :active-value="0"
          :inactive-value="1"
          :disabled="!isEdit"
        ></el-switch>
        <p style="width:600px;">开启后，必须群发内容中包含文字内容，且当助手为群主或群管理员时才会生效</p>
      </el-form-item>

      <div class="foot-wrap">
        <div class="btn-wrap">
        <el-button  type="primary" @click="testSendAction" :disabled="timer?true:false" v-if="isEdit">
             <div  class="btn-center">
                <el-icon class="el-icon--left" ><Promotion /></el-icon>
                 {{timer?timerValue+'秒后可发送':'测试发送'}}
             </div>
          </el-button>
          <div>
             <el-button type="primary" size="medium" @click="submitForm('ruleForm')">确 定</el-button >
            <el-button size="medium" @click="backAction">取 消</el-button>
          </div>
          </div>
          
       
      </div>
    </el-form>

    <GroupSelectCop ref="groupSelectDialog" @onConfirm="chooseGroupAction" />
    <GroupSelectCop ref="testSelectDialog" isSeachID @onConfirm="chooseTestAction" />
    <KeywordCop ref="keywordDialog" />
  </div>
</template>

<script>
import { unref, reactive, ref, onMounted,onUnmounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import GroupMsgCop from "@/components/GroupMsg/GroupMsgCop";
import GroupSelectCop from "@/components/GroupSelectCop";
import KeywordCop from "@/components/KeywordCop";
import { ElMessageBox, ElMessage } from "element-plus";
import { GroupCreateTest } from "@/helper/group";
import { Promotion} from "@element-plus/icons";

import {
  CreateGroupSend,
  GetDetailGroupSend,
  UpdateGroupSend,
} from "../../../helper/group.js";
import tools from "@/assets/js/tools";

export default {
  name: "CreateGroupSend",
  components: {
    GroupMsgCop,
    GroupSelectCop,
    KeywordCop,
    Promotion
  },
  setup() {
    let reClick = false 
    const SID = useRoute().query.SID;
    const isEdit = useRoute().query.isEdit==1?true:false;
    const isCopy=useRoute().query.isCopy;
    const router = useRouter();
    const ruleFormRef = ref(null);
    const groupSelectDialog = ref(null);
    const testSelectDialog = ref(null)
    const keywordDialog = ref(null);
    const groupMsgRef = ref(null);
    const ruleForm = reactive({
      name: "",
      time: "",
      group: [],
      content: [],
      askAll: 1,
      Type:1
    });

    const rules = reactive({
      name: [
        { required: true, message: "请填写名称", trigger: "blur" }
      ],
      time: [{ required: true, message: "请填写时间", trigger: "change" }],
      group: [{ required: true, message: "请选择群聊", trigger: "blur" }],
      content: [
        {
          validator: validatePass,
          required: true,
          message: "请选择群发内容",
          trigger: "change",
        },
      ],
    });

    function validatePass(rule, value, callback) {
      if (!ruleForm.content || ruleForm.content.length <= 0) {
        callback(new Error("请选择群发内容"));
      } else {
        callback();
      }
    }

    onMounted(() => {
      if (SID) {
        GetDetailGroupSend({ GroupSendID: SID }).then((res) => {
          ruleForm.askAll = res.IsHit;
          ruleForm.group = changeDataToFilter(res.groups);
          ruleForm.time = isCopy?"":res.krInfo.ExpectedSendTime;
          ruleForm.name = res.krInfo.Name;
          ruleForm.content = res.materialList;
          ruleForm.Type = res.krInfo.Type
          groupMsgRef.value.initData(res.materialList);
        });
      }
    });

    onUnmounted(()=>{
      stopTimer()
    })
    //转换本地数据数据
    function changeDataToFilter(obj) {
      let result = [];
      if (obj && obj.length > 0) {
        obj.forEach((item) => {
          let tempObj = {
            GroupName: item.ChatRoomName,
            MemberCount: item.UserCount,
            GroupID: item.ID,
          };
          result.push(tempObj);
        });
      }
      return result;
    }
    //时间禁止范围
    function isDisadbleAction(time) {
      return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
    }
    //改变当前时间
    function changeTimeAction(time) {
      var startAt = new Date(time);
      if (startAt.getTime() < new Date().getTime()) {
        // ruleForm.time = tools.GMTToStr(new Date(), 2);
        ruleForm.time=new Date()
      }
    }
    //展示关键字
    function showKeywordsAction() {
      keywordDialog.value.initCop();
    }

    //群发内容变更
    function changeMsgContent(arr) {
      if (arr && arr.length > 0) ruleFormRef.value.clearValidate("content");
      ruleForm.content = arr;
    }
    
    /*******************群选择******************/
    //选择群
    function selectGroup(arr) {
      groupSelectDialog.value.initCop(0, ruleForm.group,isEdit);
    }

    //确定群
    function chooseGroupAction(arr) {
      if (arr && arr.length > 0) ruleFormRef.value.clearValidate("group");
      ruleForm.group = arr;
    }

    //测试发送
    function testSendAction(){
      testSelectDialog.value.initCop(0,[],isEdit);
      
    }
    //测试选择群
    function chooseTestAction(arr){
      if (!arr || arr.length <= 0) {
        ElMessage.info('请选择测试群')
        return
      }
        let fliterMsgObj = fliterMsgContent();
        let params = {
            GroupIDs: arr.map((item) => item.GroupID),
            TextContent: fliterMsgObj.textArr,
            MaterialIDs: fliterMsgObj.noTextArr,
            IsHit: ruleForm.askAll ? 1 : 0,
        };
      ElMessageBox.confirm('确定后系统将在1分左右将内容发送至选中的群聊,您确定要发送吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
      }).then(() => {
        GroupCreateTest(params).then(res=>{
          startTimer()
          ElMessage.success('发送成功')
        })
      }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消'
            });
      });
      
    }
    const timer= ref(null)
    const timerValue= ref(0)
    function startTimer(){
      stopTimer() 
      let count = 0
      timer.value = setInterval(() => {
        count++
        if(count>=60){
          stopTimer()
        }
        timerValue.value = 60 - count
      },1000);
    }

    function stopTimer(){
      if(timer.value){
        clearInterval(timer.value);
        timer.value = null
        timerValue.value = 0
      } 
    }

    //返回
    function backAction() {
      router.go(-1);
    }

    /*************提交*****************/
    function fliterMsgContent() {
      let textArr = [];
      let noTextArr = [];

      ruleForm.content.forEach((item) => {
        if (item.Type == 2001) {
          textArr.push(item.MsgContent);
        } else {
          noTextArr.push(item.ID);
        }
      });

      return { textArr: textArr, noTextArr: noTextArr };
    }
    //确定表单
    const submitForm = async () => {
      if(reClick) return
      reClick = true

      const form = unref(ruleFormRef);
      if (!form) return;
      try {
        await form.validate();
        if(!isEdit){
          router.go(-1);
          return
        }
        let fliterMsgObj = fliterMsgContent();
        let params = {
          Name: ruleForm.name,
          GroupIDs: ruleForm.group.map((item) => item.GroupID),
          TextContent: fliterMsgObj.textArr,
          MaterialIDs: fliterMsgObj.noTextArr,
          IsHit: ruleForm.askAll ? 1 : 0,
          ExpectedSendTime: tools.GMTToStr(ruleForm.time, 2),
          Type:ruleForm.Type
        };
        if (SID&&!isCopy) {
          params.ID = SID;
          UpdateGroupSend(params).then((res) => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            router.go(-1);
            reClick = false
          }).catch(err=>{
            reClick = false
          })
        } else {
          CreateGroupSend(params).then((res) => {
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            router.go(-1);
            reClick = false
          }).catch(err=>{
            reClick = false
          })
        }
      } catch (error) {
        reClick = false
        console.log(error);
      }
    };

    return {
      rules,
      ruleFormRef,
      ruleForm,
      groupSelectDialog,
      chooseGroupAction,
      selectGroup,
      isDisadbleAction,
      changeTimeAction,
      showKeywordsAction,
      keywordDialog,
      submitForm,
      backAction,
      changeMsgContent,
      validatePass,
      groupMsgRef,
      changeDataToFilter,
      isEdit,
      testSendAction,
      testSelectDialog,
      chooseTestAction,
      timerValue,
      timer,

    };
  },
};
</script>
<style lang='scss' scoped>
.container-wrap {
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
}
.top-wrap {
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;

  background-color: #f2f2f2;
  font-size: 14px;
  line-height: 24px;
  color: $color-text;
  span {
    color: $color-common;
  }
}

.ruleForm {
  width: 400px;
  margin: 30px 20px;
  p {
    font-size: 12px;
    color: #999;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}

.foot-wrap {
  width: 600px;
  margin-top: 10px;
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  p {
    color: red;
  }
  .btn-wrap {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.btn-center{
  display: flex;
  align-items: center;
}
</style>