<template>
  <div class="container">
    <el-dialog title="添加图片" width="600px" v-model="dialogVisible">
      <!-- header -->
      <div class="header-wrap">
        <el-input
          placeholder="请输入图片名称/编号"
          prefix-icon="el-icon-search"
          v-model="searchObj.key"
          style="width: 200px"
          @change="serachImages"
        ></el-input>
        <div>
          <p>上传图片不超过4M</p>
          <el-upload
            class="avatar-uploader"
            accept="image/jpeg,image/jpg,image/png,image/gif"
            action=""
            :http-request="changeImage"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :auto-false="false"
          >
            <el-button type="primary">上传</el-button>
          </el-upload>
        </div>
      </div>

      <div class="body-wrap">
        <div class="cell-wrap">
          <div
            class="cell"
            :class="{
              selectBorder: item.isSelect,
              noSelectBorder: !item.isSelect,
            }"
            v-for="(item, index) in imgs"
            :key="index"
            @click="selectAction(index)"
          >
            <img :src="item.MsgContent" />
            <p>【{{item.ID}}】{{ item.Remark }}</p>
            <div class="cell-tip" v-if="item.isSelect">
              <el-icon :size="25" :color="'#BFB18A'">
                <CircleCheckFilled />
              </el-icon>
            </div>
          </div>
        </div>

      </div>
        <div class="page-foot">
          <TableFoot
            :pagination="pageObj"
            @changePage="changePage"
            @changeSize="changeSize"
          />
        </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { CircleCheckFilled } from "@element-plus/icons";
import TableFoot from "@/components/TableFoot"
import { ElMessage } from "element-plus";
import { GetMaterialList, CreateImageOrVideo } from "@/helper/material";

export default {
  components: {
    CircleCheckFilled,TableFoot
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const existCount = ref(0);
    const limitCount = ref(0);

    const searchObj = reactive({
      key: "",
    });
    const pageObj = reactive({
      pageIndex: 1,
      pageSize: 10,
      TotalRow: 0,
    });

    function initCop(limit, count) {
      dialogVisible.value = true;
      limitCount.value = limit;
      existCount.value = count;
      selectImgs.value = [];
      imgs.value = [];
      searchObj.key = ''
      serachImages();
    }

    //图片
    const imgs = ref([]);
    const selectImgs = ref([]);

    //搜索
    function serachImages() {
      let param = {
        Type: 2002,
        PageNum: pageObj.pageIndex,
        PageSize: pageObj.pageSize,
        Content:searchObj.key
      };
      GetMaterialList(param).then((res) => {
        pageObj.TotalRow = res.TotalCount;
        imgs.value = res.List;
        res.List.forEach((item) => {
          item.isSelect = false;
        });
      });
    }

    /***************点击事件******************/
    //确定点击
    function confirmAction() {
      dialogVisible.value = false;
      context.emit("onConfirm", selectImgs.value);
    }
    let lock = false;
    //图片变更
    function changeImage(event) {
      if (lock) return;
      setTimeout(() => {
        lock = false;
      }, 1000);
      
      lock = true;
      const formData = new FormData();
      formData.append("file", event.file);
      CreateImageOrVideo({ Type: 2002, formData }).then((res) => {
        serachImages()
        ElMessage.success({
          message: "上传成功",
          type: "success",
        });
      });
    }

    //检测图片
    function beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      // var testmsg = /^image\/(jpeg|png|jpg|gif)$/.test(file.type)

      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4MB!",
        });
      }
      // if (!testmsg) {
      //   ElMessage({
      //     type: "error",
      //     message: "上传图片只支持png/jpeg/jpg/gif!",
      //   });
      // }
      return isLt2M;
    }

    //选择
    function selectAction(index) {
      let item = imgs.value[index];
      let flag = -1;
      for (let tag in selectImgs.value) {
        let imgObj = selectImgs.value[tag];
        if (imgObj.ID == item.ID) {
          flag = tag;
          break;
        }
      }

      if (flag > -1) {
        item.isSelect = false;
        selectImgs.value.splice(flag, 1);
      } else {
        if (selectImgs.value.length >= limitCount.value - existCount.value) {
          return;
        }
        item.isSelect = true;
        selectImgs.value.push(item);
      }
    }

    /****************分页********************/
     //页码改变
    function changePage(index) {
      pageObj.pageIndex = index;
      serachImages();
    }
    function changeSize(size) {
      pageObj.pageSize = size;
      pageObj.pageIndex = 1;
      serachImages();
    }


    return {
      initCop,
      changePage,
      changeSize,
      dialogVisible,
      confirmAction,
      searchObj,
      pageObj,
      imgs,
      serachImages,
      selectAction,
      beforeAvatarUpload,
      existCount,
      selectImgs,
      limitCount,
      changeImage,
    };
  },
};
</script>
<style lang='scss' scoped>
.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.body-wrap {
  width: 100%;
  overflow: auto;

  .cell-wrap {
    width: 100%;
    max-height: 350px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: space-between;
    .selectBorder {
      border: 1px solid #C1AE83;
    }
    .noSelectBorder {
      border: 1px solid #ccc;
    }

    .cell {
      position: relative;
      margin: 5px 0;
      width: 100px;
      height: 120px;
      // border: 1px solid #ccc;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }

      .cell-tip {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      p {
        width: 100%;
        height: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 20px;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  .page-foot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
// :deep().el-pager li.active {
//   color: $color-common !important;
// }
</style>